import { useMemo } from 'react';
import { normalize } from 'lib/utils';

import useProductBasicDetails from '../useProductBasicDetails';

const useProductAttributes = (id, slug, skip = false) => {
  const { loading, error, data } = useProductBasicDetails(
    id,
    slug,
    {},
    {
      skip,
    }
  );

  const attributes = useMemo(
    () =>
      !skip && data?.attributes
        ? normalize({
            data: data.attributes,
            key: 'code',
          }).data
        : null,
    [data?.attributes, skip]
  );

  // description
  const description = useMemo(
    () =>
      !skip && attributes?.description?.value
        ? attributes.description.value
        : null,
    [attributes?.description?.value, skip]
  );

  // dimension details
  const dimensions = useMemo(() => {
    if (!skip) {
      let result = [];
      const sizeGeneralArr = [];
      const sizeSeatArr = [];
      const sizeArmArr = [];
      let additionalSizesArr = [];

      if (attributes?.width?.value)
        sizeGeneralArr.push(`${attributes.width.value}"w`);
      if (attributes?.depth?.value)
        sizeGeneralArr.push(` x ${attributes.depth.value}"d`);
      if (attributes?.height?.value)
        sizeGeneralArr.push(` x ${attributes.height.value}"h`);
      const sizeGeneral = sizeGeneralArr.join('  ');

      if (attributes?.seat_width?.value)
        sizeSeatArr.push(`${attributes.seat_width.value}"w`);
      if (attributes?.seat_depth?.value)
        sizeSeatArr.push(` x ${attributes.seat_depth.value}"d`);
      if (attributes?.seat_height?.value)
        sizeSeatArr.push(` x ${attributes.seat_height.value}"h`);
      const sizeSeat = sizeSeatArr.join('   ');

      if (attributes?.arm_width?.value)
        sizeArmArr.push(`${attributes.arm_width.value}"`);
      if (attributes?.arm_height?.value)
        sizeArmArr.push(` x ${attributes.arm_height.value}"`);
      const sizeArm = sizeArmArr.join('   ');

      if (attributes?.other_dimensions?.value) {
        additionalSizesArr = JSON.parse(attributes.other_dimensions.value);
      }

      const weight = parseInt(attributes?.weight?.value || '', 10)
        ? `${attributes.weight.value} lbs`
        : '';

      if (sizeGeneral) {
        result.push({
          label: 'Overall',
          value: sizeGeneral,
        });
      }
      if (sizeSeat) {
        result.push({
          label: 'Seating',
          value: sizeSeat,
        });
      }
      if (sizeArm) {
        result.push({
          label: 'Arm Height',
          value: sizeArm,
        });
      }
      if (additionalSizesArr?.length) {
        result = result.concat(
          additionalSizesArr.map(size => ({
            label: size.key,
            value: size.value,
          }))
        );
      }
      if (weight) {
        result.push({
          label: 'Weight',
          value: weight,
        });
      }
      return result;
    }
    return [];
  }, [
    attributes?.arm_height?.value,
    attributes?.arm_width?.value,
    attributes?.depth?.value,
    attributes?.height?.value,
    attributes?.other_dimensions?.value,
    attributes?.seat_depth?.value,
    attributes?.seat_height?.value,
    attributes?.seat_width?.value,
    attributes?.weight?.value,
    attributes?.width?.value,
    skip,
  ]);

  // set product pdf
  const productPDF = useMemo(
    () =>
      !skip && attributes?.product_pdf?.value
        ? attributes.product_pdf.value
        : null,
    [attributes?.product_pdf?.value, skip]
  );

  // set assemby details pdf
  const assemblyDetailsPDF = useMemo(
    () =>
      !skip && attributes?.product_flyer?.value
        ? attributes.product_flyer.value
        : null,
    [attributes?.product_flyer?.value, skip]
  );

  // construction details
  const constructionDetails = useMemo(() => {
    const constructionDetailsArr = [];
    if (!skip && attributes?.construction_details?.value) {
      const arr = attributes.construction_details.value
        .trim()
        .replace(/(\r\n|\n|\r)/gm, '')
        .split('<br>');

      for (const item of arr) {
        if (item) {
          const itemDef = item.split(':');
          if (itemDef?.length) {
            const constructionValue = {};
            if (itemDef.length < 2) {
              constructionValue.label = '';
              constructionValue.description = itemDef[0].trim();
            } else if (itemDef.length === 2) {
              constructionValue.label = itemDef[0].trim();
              constructionValue.description = itemDef[1].trim();
            }
            constructionDetailsArr.push(constructionValue);
          }
        }
      }
    }
    return constructionDetailsArr;
  }, [attributes?.construction_details?.value, skip]);

  // product care
  const productCareDetails = useMemo(() => {
    const productCareArr = [];
    if (!skip && attributes?.product_care?.value) {
      const arr = attributes.product_care.value
        .trim()
        .replace(/(\r\n|\n|\r|")/gm, '')
        .split('<br>');
      for (const item of arr) {
        if (item) {
          const itemDef = item.split(':');
          if (Array.isArray(itemDef) && itemDef?.length > 1) {
            productCareArr.push(itemDef[1].trim());
          }
        }
      }
    }

    return productCareArr;
  }, [attributes?.product_care?.value, skip]);

  // setting seo attributes
  const SEOAttributes = useMemo(() => {
    if (!skip) {
      return {
        meta_description: attributes?.meta_description?.value ?? null,
        meta_keywords: attributes?.meta_keywords?.value ?? null,
        canonicalURL:
          `https://fiore.buzz${attributes?.url_key?.value}` ?? null,
      };
    }

    return {};
  }, [
    attributes?.meta_description?.value,
    attributes?.meta_keywords?.value,
    attributes?.url_key?.value,
    skip,
  ]);

  return {
    loading,
    error,
    data: {
      id: data?.id,
      description,
      SEOAttributes,
      dimensions,
      productPDF,
      constructionDetails,
      productCareDetails,
      custom_properties: data?.custom_properties,
      additional_attributes: data?.additional_attributes,
      assemblyDetailsPDF,
    },
  };
};

export default useProductAttributes;
